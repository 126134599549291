<template>
  <div class="m-5">
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <h2>User stories using text-completion model</h2>
          <h6>Please, refer to this <a href="https://spada.uns.ac.id/pluginfile.php/819931/mod_resource/content/1/W04%20Software%20Requirements%20Specification%20-%20Library%20Management%20System.pdf" target="_blank">Software Requirements Specification for Library Management System</a> to write between 10 and 15 user stories using the text box below. Write one statement at a time and click the save button.</h6>
          <h6>Use the following template: <i>As a {role} I want to {action} so that {benefit}.</i> Refer to <a href="https://www.parabol.co/blog/user-story-examples/#user-story-examples-for-dashboards" target="_blank">this website</a> to read some examples of user stories.</h6>
          <h6 v-if="useModelFlag">Use your keyboard <strong>arrows</strong> to navigate between the text completion options and the <strong>TAB</strong> key to select an option.</h6>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <p class="text-right pt-5">
            <strong>Total characters:</strong> {{totalCharacters}}, <strong>Total characters written:</strong> {{totalCharactersWritten}}, <strong>Keystroke saving:</strong> {{keystrokeSaving}}
          </p>
        </b-col>
      </b-row>
    </b-container>
    <div class="pad-container">
      <div id="textarea" tabindex="1" class="autocomplete-container" contenteditable @keyup="searchCompletion"></div>
    </div>
    <div id="autocompletion-window" v-show="showAutoComplete" :style="{ top: top+'px', left: left+'px' }" :contenteditable="false">
      <div class="items">
        <ul>
          <li v-for="(s, index) in predictions"
              :class="{ 'active': activeIndex.id === index }" :key="s[0]"
              @click="setActive(index)">
              {{ s[1] }}
          </li>
        </ul>
      </div>
    </div>
    <b-form-group id="input">
      <span v-show="showModelOption"><b-form-checkbox v-model="useModelFlag" name="check-useModel">&nbsp;<strong>Use</strong> text prediction model</b-form-checkbox></span>
    </b-form-group>
    <b-container fluid>
      <b-row align-h="between">
        <b-col class="mt-3" cols="6">
          <b-button variant="primary" @click="saveUS()">Save</b-button>&nbsp;&nbsp;
          <b-button variant="outline-primary" @click="newUS()">New</b-button>&nbsp;&nbsp;
          <b-button variant="danger" @click="deleteAllUS()">Delete all</b-button>
        </b-col>
        <b-col class="mt-3" cols="6">
          <div class="align-right" v-if="root">
            <b-form-select v-model="selectedSeed" :options="seedOptions"></b-form-select>&nbsp;&nbsp;
            <b-button variant="warning" @click="dataSeed()">Data seed</b-button>&nbsp;&nbsp;
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col class="mt-3">
          <b-card-group v-for="(userstory, index) in prepareUSCard" :key="index">
            <b-card class="m-1" :sub-title="userstory.subtitle">
                <b-card-text>
                  {{userstory.text}}
                </b-card-text>
                <b-button variant="outline-danger" @click="deleteUS(userstory._id)">Delete</b-button>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'App',
  components: {
    
},
  data() {
      return {
        top: '',
        left: '',
        root: false,
        showModelOption: false,
        predictions: [],
        showAutoComplete: false,
        userstories: [],
        useModelFlag: true,
        typeCompletion: '',
        keystrokeSaving: 0,
        totalCharacters: 0,
        totalCharactersWritten: 0,
        totalWords: 0,
        activeIndex: {
          id: 0,
        },
        selectedSeed: 1,
        seedOptions:[
          { value: 1, text: 'Seed US01 (#g02)' },
          { value: 2, text: 'Seed US02 (#g03)' },
          { value: 3, text: 'Seed US03 (#g04)' },
          { value: 4, text: 'Seed US04 (#g05)' },
          { value: 5, text: 'Seed US05 (#g08)' },
          { value: 6, text: 'Seed US06 (#g10)' },
          { value: 7, text: 'Seed US07 (#g11)' },
          { value: 8, text: 'Seed US08 (#g12)' },
          { value: 9, text: 'Seed US09 (#g13)' },
          { value: 10, text: 'Seed US10 (#g14)' },
          { value: 11, text: 'Seed US11 (#g16)' },
          { value: 12, text: 'Seed US12 (#g17)' },
          { value: 13, text: 'Seed US13 (#g18)' },
          { value: 14, text: 'Seed US14 (#g19)' },
          { value: 15, text: 'Seed US15 (#g21)' },
          { value: 16, text: 'Seed US16 (#g22)' },
          { value: 17, text: 'Seed US17 (#g23)' },
          { value: 18, text: 'Seed US18 (#g24)' },
          { value: 19, text: 'Seed US19 (#g25)' },
          { value: 20, text: 'Seed US20 (#g26)' },
          { value: 21, text: 'Seed US21 (#g27)' },
          { value: 22, text: 'Seed US22 (#g28)' },
          { value: 23, text: 'Seed RE01 (#)' },
          { value: 24, text: 'Seed RE02 (#)' },
          { value: 25, text: 'Seed RE03 (#)' },
          { value: 26, text: 'Seed RE04 (#)' },
          { value: 'template', text: 'Template' }
        ]
      }
  },

  methods: {
    async dataSeed(){
      if(this.selectedSeed>22){
        await axios.post('/re/'+this.selectedSeed);
      } else {
        await axios.get('/seed/'+this.selectedSeed);
      }
      
      this.getData()
    },
    async deleteAllUS(){
      await axios.delete('/us');
      this.getData()
    },
    cleanMetrics(){
      this.totalCharacters = 0
      this.totalCharactersWritten = 0
      this.totalWords = 0
      this.keystrokeSaving = 0
    },
    newUS(){
      document.getElementById('textarea').innerText = '';
      this.placeCaretAtEnd(document.getElementById('textarea'))
      this.showAutoComplete=false
      this.cleanMetrics()
    },
    async saveUS(){
      var objMetrics = {chaTotal: this.totalCharacters, chaWritten: this.totalCharactersWritten, keystrokeSaving: this.keystrokeSaving};
      var returnMetrics = await axios.post('/metrics/', objMetrics);
      var obj = {
        text: document.getElementById('textarea').innerText,
        metrics: returnMetrics.data._id
      }
      await axios.post('/us/', obj);
      document.getElementById('textarea').innerText = "";
      this.getData()
      this.cleanMetrics()
      this.showAutoComplete=false
      document.getElementById('textarea').focus()
    },
    async deleteUS(id){
      await axios.delete('/us/'+id)
      this.getData()
    },
    async getData(){
      this.userstories = await axios.get('/us')
    },
    async searchCompletion(e){
      if(e.key=='Shift'||e.key=="ArrowUp"||e.key=="ArrowDown"||e.key=="ArrowRight"||e.key=="ArrowLeft"||e.key=="CapsLock"||e.key=="Alt"||e.key=="Meta"||e.key=="Control") return
      var text = e.target.innerText.replace(/  +/g, ' ');
      this.showAutoComplete = false;
      this.predictions = [];
      this.setActive(0);
      if(text==''||text==' '||text==null) return;
      if(this.useModelFlag){
        console.log('start prediction')

        if(e.key!='Backspace'){
          this.totalCharacters = text.length
          this.totalCharactersWritten += 1
          this.keystrokeSaving = (1 - (this.totalCharactersWritten/this.totalCharacters)).toFixed(2);
        }
        
        this.totalWords = text.split(" ").length
        var position = this.getCaretTopPoint()
        this.top = position.top+25
        this.left = position.left
        var nWords = text.split(" ")
        var lastWord = nWords[nWords.length - 1];
        var lastChar = text.substr(text.length - 1);
        var listIndex = 0

        if(lastChar.charCodeAt(0)==32){
          this.typeCompletion = 'n-gram'
          var n = 9
          while(n>0){
            if(n > text.split(" ").length){
              n--
              continue
            }

            let ngram = this.getInputNgram(text, n) //split ngram
            let output = await axios.get('ngram/'+ngram) //search ngram
            if(ngram!="" && ngram!=" " && ngram!=null) {
              output.data.forEach(element => {
                var finalElement = [listIndex, element.output]
                if(finalElement[1]!=""){
                  const index = this.predictions.findIndex(object => object[1] === finalElement[1]); //remove repeated entries
                  if (index === -1) {
                    this.showAutoComplete = true
                    this.predictions.push(finalElement)
                    listIndex++
                  }
                }
              });
            }
            n--;
          }
        } else {
          this.typeCompletion = '1-gram'
          let output = await axios.get('onegram/'+lastWord) //search ngram
          this.predictions=[]
          output.data.forEach(element => {
              if(lastWord!=element.output){
                var finalElement = [listIndex, element.output]
                this.predictions.push(finalElement)
                listIndex++
              }
          });

          if(this.predictions.length>0){
            this.showAutoComplete = true
          }
        }

        this.predictions = this.predictions.slice(0, 9);
      }
    },
    useOption(){
      var textarea = document.getElementById('textarea')
      let completion = this.getCompletionOnList()
      var text = textarea.innerHTML
      text = text.replace("<div><br></div>", "")
      text = text.replace("&nbsp;", " ")
      text = text.replace("<br>", "")
      if(this.typeCompletion=='n-gram'){
        textarea.innerText = text + completion + " "
      } else if(this.typeCompletion=='1-gram'){
        var textarray = text.split(' ')
        textarray[textarray.length-1] = completion
        var final = textarray.join(' ') + " "
        textarea.innerText = final
      }

      this.totalCharacters = text.length
      this.keystrokeSaving = 1 - (this.totalCharactersWritten/this.totalCharacters).toFixed(2);
      this.activeIndex.id = 0
      this.placeCaretAtEnd(textarea)
      this.showAutoComplete==false
    },
    getCompletionOnList(){
      const element = this.predictions.find(element => element[0] == this.activeIndex.id);
      return element[1];
    },
    getInputNgram(text, n){
      var textArray = text.split(' ')
      var size = textArray.length
      var begin = size - n
      var output = ''
      for(var i=begin;i<size;i++){
        output = output + textArray[i] + ' '
      }
      output = output.trim()
      return output
    },
    setActive(index) { 
      this.activeIndex.id = index;
    },
    placeCaretAtEnd(el) {
      el.focus();
      if (typeof window.getSelection != "undefined"
              && typeof document.createRange != "undefined") {
          var range = document.createRange();
          range.selectNodeContents(el);
          range.collapse(false);
          var sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
      } else if (typeof document.body.createTextRange != "undefined") {
          var textRange = document.body.createTextRange();
          textRange.moveToElementText(el);
          textRange.collapse(false);
          textRange.select();
      }
    },
    selectOption(e){
      if(e.key=='ArrowDown'){
        e.preventDefault();
        if(this.activeIndex==undefined){
          this.setActive(0)
        } else {
          this.setActive(this.activeIndex.id + 1)
        }
      } else if(e.key=='ArrowUp'){
        e.preventDefault();
        if(this.activeIndex==undefined){
          this.setActive(0)
        } else {
          this.setActive(this.activeIndex.id - 1)
        }
      } else if(e.key=="Tab"){
        if(this.showAutoComplete==true){
          e.preventDefault()
          this.useOption();
        }
      } else {
        return;
      }
    },
    getCaretTopPoint () {
      const sel = document.getSelection()
      const r = sel.getRangeAt(0)
      let rect
      let r2
      // supposed to be textNode in most cases
      // but div[contenteditable] when empty
      const node = r.startContainer
      const offset = r.startOffset
      if (offset > 0) {
        // new range, don't influence DOM state
        r2 = document.createRange()
        r2.setStart(node, (offset - 1))
        r2.setEnd(node, offset)
        // https://developer.mozilla.org/en-US/docs/Web/API/range.getBoundingClientRect
        // IE9, Safari?(but look good in Safari 8)
        rect = r2.getBoundingClientRect()
        return { left: rect.right, top: rect.top }
      } else if (offset < node.length) {
        r2 = document.createRange()
        // similar but select next on letter
        r2.setStart(node, offset)
        r2.setEnd(node, (offset + 1))
        rect = r2.getBoundingClientRect()
        return { left: rect.left, top: rect.top }
      } else { // textNode has length
        // https://developer.mozilla.org/en-US/docs/Web/API/Element.getBoundingClientRect
        rect = node.getBoundingClientRect()
        const styles = getComputedStyle(node)
        const lineHeight = parseInt(styles.lineHeight)
        const fontSize = parseInt(styles.fontSize)
        // roughly half the whitespace... but not exactly
        const delta = (lineHeight - fontSize) / 2
        return { left: rect.left, top: (rect.top + delta) }
      }
    }
  },
  created(){
    let urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('p')){
      if(urlParams.get('p')=="1"){
        this.useModelFlag = true;
        this.showModelOption = true;
      } else {
        this.useModelFlag = false;
        this.showModelOption = false;
      }
    } else {
      this.useModelFlag = true;
      this.showModelOption = true;
    }

    if(urlParams.has('root')){
      if(urlParams.get('root')=="1"){
        this.root = true;
      } else {
        this.root = false;
      }
    } else {
      this.root = false;
    }
    this.getData()
  },
  mounted() {
    window.addEventListener('keydown', this.selectOption);
  },
  computed: {
    prepareUSCard(){
      var obj = []
      if(Array.isArray(this.userstories.data)){
        this.userstories.data.forEach(element => {
          var subtitle = "total characters: " +  element.metrics.chaTotal + ", total characters written: " + element.metrics.chaWritten + ", keystroke saving: " + element.metrics.keystrokeSaving;
          var row = {text: element.text, subtitle: subtitle, _id: element._id};
          obj.push(row)
        });
      }
      return obj;
    }
  }
}
</script>

<style>
.pad-container {
  padding: 5px;
  border-radius: 5px;
  border: medium solid;
  border-color: #ff7575;
}
.autocomplete-container {
  font-family: "Crete Round";
  font-size: 16px;
  border-radius: 8px;
  height: 200px;
  width: 100%;
  text-align: left;
  caret-color: #ff7575;
  padding: 5px;
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
span {
  display: inline-block;
  outline: none;
}

#autocompletion-window {
  position: absolute;
  background-color: #f2f2f2;
  border-color: darkgrey;
  padding: 0px;
  width: 300px;
  z-index: 20;
}

.items ul{
  list-style-type: none;
  padding: 0;
}

.items li {
  width: 300px;
  padding-left: 3px;
}

.items li.selected {
  background-color: grey;
}

.active{
  background-color: rgb(92, 92, 92);
}

.align-right{
  text-align: right;
}
</style>
